.termsView {
  table {
    border-collapse: collapse;
    width: 100%; 
  }

  th,
  td {
    border: 1px solid black; 
    padding: 8px; 
    text-align: left; 
  }

  th {
    background-color: #f2f2f2;
  }
}
