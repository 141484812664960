.generalSearchForm {
  .nextStepButton {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #e0e0e0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .custom-checkbox {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    cursor: pointer;
    accent-color: #022b54;
    margin-bottom: 8px;
  }

  label {
    display: flex;
    align-items: center;
  }
}
