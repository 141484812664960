.myMainNavBar {
  .paradise-mud-navbar {
    position: fixed;
    z-index: 3;
    background-color: #ffff;
  }

  .paradise-mud-mobile-navbar {
    background-color: #ffff;
    width: -webkit-fill-available;
    position: fixed;
    padding: 0px;
  }

  .paradise-mud-mobile-navbar img {
    filter: brightness(0) saturate(100%) invert(28%) sepia(77%) saturate(500%) hue-rotate(168deg)
      brightness(90%);
  }

  .paradise-mud-mobile-navbar-modal {
    background-color: #ffff;
  }

  .paradise-mud-mobile-navbar-hamburger {
    filter: unset;
  }

  .paradise-mud-navbar-link {
    color: #666161;
    font-size: 0.85em;
    font-weight: 600;
    font-family: cutive;
  }

  .transparent-navbar {
    background-color: #e4e4e400;
  }

  .paradise-mud-navbar-logo-img {
    height: 72px;
  }

  .transparent-navbar .paradise-mud-navbar-link {
    color: #fcfafa;
    font-size: 0.85em;
    font-weight: 600;
    font-family: cutive;
  }

  .paradise-mud-navbar-link-container {
    padding-right: 30px;
  }

  @media only screen and (min-width: 600px) {
    .paradise-mud-navbar {
      padding: 5px 10px !important;
    }
  }

  @media only screen and (min-width: 1025px) {
    .paradise-mud-navbar {
      padding: 0px 30px !important;
    }
  }
}

.myMainNavBarHideToogle {
  .paradise-mud-navbar {
    position: fixed;
    z-index: 3;
    background-color: #ffff;
  }

  .paradise-mud-mobile-navbar {
    background-color: #ffff;
    width: -webkit-fill-available;
    position: fixed;
    padding: 0px;
  }

  .paradise-mud-mobile-navbar img {
    filter: brightness(0) saturate(100%) invert(28%) sepia(77%) saturate(500%) hue-rotate(168deg)
      brightness(90%);
  }

  .paradise-mud-mobile-navbar-modal {
    background-color: #ffff;
  }

  .paradise-mud-mobile-navbar-hamburger {
    display: none;
  }

  .paradise-mud-navbar-link {
    color: #666161;
    font-size: 0.85em;
    font-weight: 600;
    font-family: cutive;
  }

  .transparent-navbar {
    background-color: #e4e4e400;
  }

  .paradise-mud-navbar-logo-img {
    height: 72px;
  }

  .transparent-navbar .paradise-mud-navbar-link {
    color: #fcfafa;
    font-size: 0.85em;
    font-weight: 600;
    font-family: cutive;
  }

  .paradise-mud-navbar-link-container {
    padding-right: 30px;
  }

  @media only screen and (min-width: 600px) {
    .paradise-mud-navbar {
      padding: 5px 10px !important;
    }
  }

  @media only screen and (min-width: 1025px) {
    .paradise-mud-navbar {
      padding: 0px 30px !important;
    }
  }
}
