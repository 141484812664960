.blogView {
  .rightTextBox {
    position: absolute;
    top: 14%;
    transform: translateY(-50%);
    color: #999;
    right: 80px;
  }
  .banner {
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      filter: brightness(0.62);
    }
    .info-box {
      margin-top: 300px;
      z-index: 3;
      position: absolute;
      color: white;
    }
  }
}
