@import '../vars.module';

// Fonts
.h1 {
  font-size: $font44;
}
.h2 {
  font-size: $font34;
}
.h3 {
  font-size: $font24;
}
.h4 {
  font-size: $font20;
}
.h4-loan {
  font-size: $font20;
}

.Body1 {
  font-size: $font16;
}
.Body2 {
  font-size: $font14;
}
.subTitle1 {
  font-size: $font16;
  font-weight: bold;
  font-family: 'Cutive', serif;
}
.subTitle2 {
  font-size: $font14;
  font-weight: bold;
  font-family: 'Cutive', serif;
}

.subTitle3 {
  font-size: $font18;
  font-weight: bold;
  font-family: 'Cutive', serif;
}

.subTitle4 {
  font-size: $font12;
  font-weight: bold;
  font-family: 'Cutive', serif;
}

.caption {
  font-size: $font12;
}

.caption-large {
  font-size: $font20;
  font-weight: bold;
}

.offerModalTitle {
  font-size: 34px;
  line-height: 1.29;
  font-family: 'Cutive', serif;
  color: $gray500;
}

.overline {
  font-size: $font10;
  font-weight: bold;
  letter-spacing: 0.5px;
}

.H1 {
  font-size: 44px;
  line-height: 1.09;
  font-family: 'Cutive', serif;
  font-weight: bold;
  color: $gray500;
}

.H1-graph {
  font-size: 44px;
  line-height: 1.09;
  font-family: 'Cutive', serif;
  font-weight: bold;
  color: $gray500;
}

// same size in mobile
.H1-fixed {
  font-size: 44px;
  line-height: 1.09;
  font-family: 'Cutive', serif;
  font-weight: bold;
  color: $gray500;
}

.H2 {
  font-size: 34px;
  line-height: 1.29;
  font-family: 'Cutive', serif;
  color: $gray500;
}

.H3 {
  font-size: 28px;
  line-height: 1.43;
  font-family: 'Cutive', serif;
  font-weight: bold;
  color: $gray500;
}

.H4 {
  font-size: 24px;
  line-height: 1.33;
  font-family: 'Cutive', serif;
  font-weight: bold;
  color: $gray500;
}

.H4Half {
  font-size: $font22;
  line-height: 1.35;
  font-family: 'Cutive', serif;
  font-weight: bold;
  color: $gray500;
}

.H5 {
  font-size: 20px;
  line-height: 1.4;
  font-family: 'Cutive', serif;
  font-weight: bold;
  color: $gray500;
}

.H5-gray050 {
  font-size: 20px;
  line-height: 1.4;
  font-family: 'Cutive', serif;
  font-weight: bold;
  color: $gray050;
}

.Subtitle-1 {
  font-size: 16px;
  line-height: 1.5;
  font-family: 'Cutive', serif;
  font-weight: bold;
  color: $gray400;
}

.Body-1 {
  font-size: 16px;
  line-height: 1.5;
  font-family: 'Cutive', serif;
  color: $gray400;
}

.Heading {
  font-size: 36px;
  line-height: 48px;
  font-family: 'Cutive', serif;
}

.HeadingBold {
  font-size: 36px;
  font-family: 'Cutive', serif;
}

.m-Heading {
  font-size: 24px;
  line-height: 32px;
  font-family: 'Cutive', serif;
}

.header-description {
  font-size: 16px;
  font-family: 'Cutive', serif;
  color: $gray400;
}

.Subtitle-2 {
  font-size: 14px;
  line-height: 1.43;
  font-family: 'Cutive', serif;
  font-weight: bold;
  color: $gray400;
}

.Subtitle-3 {
  font-size: 12px;
  line-height: 1.43;
  font-family: 'Cutive', serif;
  color: $gray400;
}

.Body-2 {
  font-size: 14px;
  line-height: 1.43;
  font-family: 'Cutive', serif;
  color: $gray400;
}

.caption {
  font-size: 12px;
  line-height: 1.33;
  font-family: 'Cutive', serif;
  color: $gray400;
}

.captionBold {
  font-size: 12px;
  line-height: 1.33;
  font-family: 'Cutive', serif;
  color: $gray400;
}

.cardTitle {
  font-size: $font20;
  color: $gray400;
}

.Overline {
  font-size: 10px;
  line-height: 1.6;
  letter-spacing: 0.5px;
  font-family: 'Cutive', serif;
  color: $gray400;
}

.labelOverline {
  line-height: 1.6;
  letter-spacing: 0.5px;
  font-family: 'Cutive', serif;
}

.link {
  color: $blue100;
  font-weight: bold;
  cursor: pointer;
}

.linkDark {
  color: $blue050;
  font-weight: bold;
  cursor: pointer;
}

.otherLink {
  &:hover {
    color: $blue300 !important;
    cursor: pointer;
  }
}

@media (max-width: $breakpoint-tablet-max) {
  .H1,
  .mH4 {
    // = H4
    font-size: 24px;
    line-height: 1.33;
  }

  .H1-graph {
    font-size: 34px;
    line-height: 1.29;
  }

  .caption-large {
    font-size: $font12;
    font-weight: normal;
  }

  .H3 {
    // = H5
    font-size: 20px;
    line-height: 1.4;
  }

  .mH3,
  .m-H3 {
    font-size: 28px;
  }

  .m-H4 {
    font-size: 24px;
    line-height: 1.33;
    font-family: 'Cutive', serif;
    font-weight: bold;
    color: $gray500;
  }
  .mH5,
  .m-H5 {
    font-size: 20px;
  }
  .mBody-1 {
    font-size: 16px;
  }

  .mBody-2 {
    font-size: 14px;
  }

  .mSubtitle-1 {
    font-size: 16px;
    line-height: 1.5;
  }

  .mSubtitle-2 {
    font-size: 14px;
    line-height: 1.43;
    font-family: 'Cutive', serif;
    font-weight: bold;
    color: $gray400;
  }

  .mNotBold {
    font-family: 'Cutive', serif;
    font-weight: normal;
  }
  .mCaption {
    font-size: 12px;
    line-height: 1.33;
    font-weight: normal;
    font-family: 'Cutive', serif;
  }

  .header-description {
    font-size: 14px;
  }

  .mobile-caption {
    font-size: 12px;
  }

  .mobile-Subtitle-2 {
    font-size: 14px;
  }

  .mobile-Body-1 {
    font-size: 16px;
    line-height: 1.5;
    font-family: 'Cutive', serif;
    color: $gray400;
  }
}

@media (max-width: $breakpoint-mobile-max) {
  .cardTitle {
    font-size: $font14;
  }
  .offerModalTitle {
    font-size: 24px;
  }
}
