.NewSpaceCard {
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper-button-next {
    color: rgba(255, 254, 254, 0);
  }

  .swiper-button-prev {
    color: rgba(255, 255, 255, 0);
  }

  .swiper-pagination-bullet-active {
    background-color: #fff;
  }

  @media only screen and (min-width: 600px) {
    .spaceImage {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
  }
}
