.icon-container {
    position: absolute;
    top: 20px;
    right: 10px;
    cursor: pointer;
    z-index: 1000;
    transition: transform 0.2s, color 0.2s;
    color: #808080;
  
    &:hover {
      transform: scale(1.1);
      color: #505050;
    }
  }
  