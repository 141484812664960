.myCustomerExperienceCarousel {
  z-index: 0 !important;

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(0.6);
  }

  .swiper-slide video {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(0.6);
  }

  .swiper-button-next,
  .swiper-button-prev {
    width: 40px;
    height: 40px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 14px;
    color: grey;
  }

  .swiper-pagination-bullet-active {
    background-color: #fff;
  }

  .sliderInfo-box {
    z-index: 3;
    position: absolute;
    font-family: cutive;
    text-align: start;
    color: #ffffff;
    font-size: 20px;
    width: 80%;
  }
}
