.floating-button {
  z-index: 3;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%); 
  background-color: #333333c4;
  color: white;
  border: none;
  border-radius: 50px;
  padding: 15px 20px;
  font-size: 14px;
  cursor: pointer;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
}

.floating-button:hover {
  background-color: #333333de;
}
