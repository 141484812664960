@import '../vars.module';

.absCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.position-relative {
  position: relative;
}

.pos-rel {
  position: relative;
}

.pos-abs {
  position: absolute;
}

.pos-fixed {
  position: fixed;
}

.pos-static {
  position: static;
}

// feel free to add as needed
$positions_list: 0, 1, 5, 8, 10, 12, 15, 16, 20, 30, 32, 36, 40;

@each $position in $positions_list {
  .top#{$position} {
    top: $position * 1px;
  }
  .topN#{$position} {
    top: $position * -1px;
  }
  .bottom#{$position} {
    bottom: $position * 1px;
  }
  .bottomN#{$position} {
    bottom: $position * -1px;
  }
  .left#{$position} {
    left: $position * 1px;
  }
  .leftN#{$position} {
    left: $position * -1px;
  }
  .right#{$position} {
    right: $position * 1px;
  }
  .rightN#{$position} {
    right: $position * -1px;
  }
}

@media (min-width: $breakpoint-tablet-min) and (max-width: $breakpoint-tablet-max) {
  @each $position in $positions_list {
    .t-top#{$position} {
      top: $position * 1px;
    }
    .t-topN#{$position} {
      top: $position * -1px;
    }
    .t-bottom#{$position} {
      bottom: $position * 1px;
    }
    .t-bottomN#{$position} {
      bottom: $position * -1px;
    }
    .t-left#{$position} {
      left: $position * 1px;
    }
    .t-leftN#{$position} {
      left: $position * -1px;
    }
    .t-right#{$position} {
      right: $position * 1px;
    }
    .t-rightN#{$position} {
      right: $position * -1px;
    }
  }
}

@media (max-width: $breakpoint-mobile-max) {
  .m-pos-abs {
    position: absolute;
  }
  .m-pos-fixed {
    position: fixed;
  }
  .m-pos-static {
    position: static;
  }

  @each $position in $positions_list {
    .m-top#{$position} {
      top: $position * 1px;
    }
    .m-topN#{$position} {
      top: $position * -1px;
    }
    .m-bottom#{$position} {
      bottom: $position * 1px;
    }
    .m-bottomN#{$position} {
      bottom: $position * -1px;
    }
    .m-left#{$position} {
      left: $position * 1px;
    }
    .m-leftN#{$position} {
      left: $position * -1px;
    }
    .m-right#{$position} {
      right: $position * 1px;
    }
    .m-rightN#{$position} {
      right: $position * -1px;
    }
  }
}
