.productDetails{
  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-button-next,
  .swiper-button-prev {
    width: 40px;
    height: 40px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 14px;
    color: grey;
  }
}
