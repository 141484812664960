@import '../vars.module';

.grayShadow {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
}
.borderTp {
  border-top: 1px solid;
}
.borderNone {
  border: none !important;
}
.border1 {
  border: 1px solid;
}
.borderDashed {
  border: 1px dashed;
}

.borderRight1Neutral050 {
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: $neutral050;
}
.borderRight1Gray1 {
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: $gray1;
}

.borderBottom1 {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.borderLeft1 {
  border-left-style: solid;
  border-left-width: 1px;
}

.borderRight1 {
  border-right-style: solid;
  border-right-width: 1px;
}

.borderLeft4Blue100 {
  border-left-style: solid;
  border-left-width: 4px;
  border-left-color: $blue100;
}
.borderLeft4White {
  border-left-style: solid;
  border-left-width: 4px;
  border-left-color: white;
}
.borderBottomNeutral025 {
  border-bottom-color: $neutral025;
}
.borderNeutral050 {
  border-color: $neutral050;
}
.borderColorWhite {
  border-color: $white;
}
.borderTpGray050 {
  border-top: 1px solid $gray050;
}
.borderBmGray050 {
  border-bottom: 1px solid $gray050;
}
.borderTpGray025 {
  border-top: 1px solid $gray025;
}
.borderBmGray025 {
  border-bottom: 1px solid $gray025;
}
.borderWidth2 {
  border-style: solid;
  border-width: 2px;
}
.borderBlue100 {
  border-color: $blue100;
}
.borderBottom {
  border-bottom: 1px solid;
}
.borderColorGray025 {
  border-color: $gray025;
}
.borderWhite {
  border: 1px solid $white;
}
.borderColorGrayN300 {
  border-color: $grayN300;
}

.borderColorBlue025 {
  border-color: $blue025;
}

.borderColorGreen100 {
  border-color: $green100;
}

.borderColorRed100 {
  border-color: $red100;
}
.borderColorRed300 {
  border-color: $red300;
}
.borderColorYellow100 {
  border-color: $yellow100;
}
.grayShadow16 {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
}
.borderBottomNeutral025 {
  border-bottom-color: $neutral025;
}
.borderBottomNeutral050 {
  border-bottom-color: $neutral050;
}
.borderNeutral050 {
  border-color: $neutral050;
}
.borderNeutral075 {
  border-color: $neutral075;
}
.borderGrayN050 {
  border: 1px solid $grayN050;
}
.border2pxBlueB100 {
  border: 2px solid $blueB100;
}
.borderGray050 {
  border: 1px solid $gray050;
}
.borderwhite {
  border: 1px solid $white;
}
.borderBlack {
  border: 1px solid $black;
}

.borderRadius2 {
  border-radius: 2px;
}

.borderRadius4 {
  border-radius: 4px;
}

.borderRadius3 {
  border-radius: 3px;
}

.borderRadius6-7 {
  border-radius: 6.7px;
}

.borderRadius8 {
  border-radius: 8px;
}

.borderRadius30 {
  border-radius: 30px;
}

.borderRadius62 {
  border-radius: 62px;
}

.borderRadius16 {
  border-radius: 16px;
}
.borderRadius100 {
  border-radius: 100px;
}
.borderBottomRadius8 {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
.borderTopRadius8 {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.borderTopRadius16 {
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
}

.borderGray100 {
  border: 1px solid $gray100;
}
.borderRed3 {
  border: 2px solid $red100;
}
.borderOrange3 {
  border: 2px solid $orange100;
}
.borderYellow3 {
  border: 2px solid $yellow100;
}
.borderYellow7 {
  border: 2px solid $yellow7;
}
.borderLgreen3 {
  border: 2px solid $lgreen100;
}
.borderGreen3 {
  border: 2px solid $green100;
}
.borderGray5 {
  border: 1px solid $gray5;
}
.borderGray5Thick {
  border: 2px solid $gray5;
}
.borderLightSage {
  border-color: $lightSage;
}

.borderOfferCard {
  border-radius: $borderRadius8;
  border: 1px solid $gray050;
}
.border-rad4px {
  border-radius: $borderRadius4;
}
.border-rad8px {
  border-radius: $borderRadius8;
}
.border-rad10px {
  border-radius: $borderRadius10;
}
.border-rad16px {
  border-radius: $borderRadius16;
}
.border-rad18px {
  border-radius: $borderRadius18;
}
.border-rad24px {
  border-radius: $borderRadius24;
}
.border-rad60px {
  border-radius: $borderRadius60;
}
.border-rad100px {
  border-radius: $borderRadius100;
}

.border-rad50 {
  border-radius: 50%;
}
@media (max-width: $breakpoint-mobile-max) {
  .m-borderWidth0 {
    border-width: 0;
  }
  .m-borderNone {
    border: none !important;
  }
  .m-border-rad0px {
    border-radius: $borderRadius0;
  }
  .m-border-rad4px {
    border-radius: $borderRadius4;
  }
  .m-border-rad8px {
    border-radius: $borderRadius8;
  }
  .m-border-rad10px {
    border-radius: $borderRadius10;
  }
  .m-border-rad16px {
    border-radius: $borderRadius16;
  }
  .m-border-rad18px {
    border-radius: $borderRadius18;
  }
  .m-border-rad24px {
    border-radius: $borderRadius24;
  }
  .m-border-rad60px {
    border-radius: $borderRadius60;
  }
  .m-border-rad100px {
    border-radius: $borderRadius100;
  }
}
@media screen and (max-width: $breakpoint-tablet-max) {
  .link-notification-border-top {
    border-top: 1px solid $gray2;
  }
}
