.mySwiper {
  .swiper {
    width: 100%;
    height: 100%;
    z-index: -1 !important;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative; /* Necesario para posicionar el gradiente */
    overflow: hidden; /* Asegura que el gradiente no se salga del contenedor */
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper-slide::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
    z-index: 1; /* Coloca el gradiente encima de la imagen */
    pointer-events: none; /* Evita interferencias con la interacción */
  }

  .swiper-button-next {
    color: rgba(255, 254, 254, 0);
  }

  .swiper-button-prev {
    color: rgba(255, 255, 255, 0);
  }

  .swiper-pagination-bullet-active {
    background-color: #fff;
  }

  .sliderInfo-box {
    margin-top: 30%;
    z-index: 2;
    position: absolute;
    color: #ffffff;
    font-size: 20px;
    width: 95%;
  }

  .sliderInfo-box-titles {
    font-family: cutive;
  }

  .sliderInfo-box-input {
    width: 100%;
    position: relative;
  }

  .sliderFormInput {
    background-color: #fefefe4d;
    font-weight: bold;
  }

  .sliderFormInput::placeholder {
    color: #ffff !important;
    opacity: 1 !important;
  }

  .leftTextBox {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #999;
    margin-left: 20px;
  }

  .rightTextBox {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #999;
    right: 20px;
  }

  .dropdown-content {
    position: absolute;
    top: 100%;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 95%;
    padding: 20px;
    z-index: 10;
    overflow-y: auto;
  }

  .sliderImage {
    height: 360px !important;
  }

  @media only screen and (min-width: 600px) {
    .sliderInfo-box-input {
      margin-top: 15% !important;
      width: 570px;
    }
    .sliderImage {
      height: 580px !important;
    }
  }

  @media only screen and (min-width: 800px) {
    .sliderInfo-box {
      width: 45% !important;
      margin-top: 15% !important;
    }
    .sliderInfo-box-input {
      margin-top: 10% !important;
      width: 480px;
    }
    .dropdown-content {
      width: 60%;
    }
    .sliderImage {
      height: 800px;
    }
  }
}
